import React, { useEffect, useState } from 'react';
import { fetchPermissibleSites, getDataForAllSites, auth } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Paper, List, ListItem, ListItemText} from '@mui/material';
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore'; 
import { db } from '../utils/firebase'; 
import { Person, Logout } from '@mui/icons-material';
import './admin.css';
import axios from 'axios';

function Admin({ currentUser }) {
  const [sites, setSites] = useState([]);
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState(null);

  useEffect(() => {
    if (currentUser) {
      // Fetch permissible sites for the current user
      fetchPermissibleSites(currentUser.email)
        .then((fetchedSites) => {
          setSites(fetchedSites);
        })
        .catch((error) => console.error('Error fetching sites:', error));

      // Fetch user data from Firestore using email
      const fetchUserName = async () => {
        try {
          const q = query(collection(db, 'userAccounts'), where('email', '==', currentUser.email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data(); // Get the first document that matches
            setUserName(userData.name); 
          } else {
            console.error('No user document found!');
          }
        } catch (error) {
          console.error('Error fetching user document:', error);
        }
      };

      fetchUserName(); // Trigger the fetch
    }
  }, [currentUser]);

  const handleSiteClick = async (site) => {
    setLoading(true);
    try {
      // Fetch siteConfig.json for the clicked site
      const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(site)}`;
      const siteConfigUrl = `${baseUrl}/siteConfig.json`;

      const siteConfigResponse = await axios.get(siteConfigUrl);
      const siteConfig = siteConfigResponse.data;

      const dateFolders = siteConfig.dateFolders || [];
      
      if (dateFolders.length === 0) {
        console.error(`No date folders found for site ${site}`);
        return;
      }

      // Sort the date folders and get the most recent one
      dateFolders.sort((a, b) => b.date.localeCompare(a.date));
      const latestDateFolder = dateFolders[0];  // Use the most recent date

      // Navigate to the site dashboard and pass the necessary data (site name and latest date)
      console.log('site name in admin.js' ,site);
      navigate(`/site-dashboard/${encodeURIComponent(site)}`, {
        state: { siteName: site, latestDate: latestDateFolder.date }
      });
    } catch (error) {
      console.error('Error fetching siteConfig:', error);
    }
    setLoading(false);
  };
  

  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        console.log('User signed out');
        navigate('/login'); // Redirect to login page
      })
      .catch((error) => {
        console.error('Sign out error', error);
      });
  };

  
  return (

    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
      <Typography variant="h4" gutterBottom>
        Welcome, {userName || 'User'}
      </Typography>

      <Paper sx={{
                      boxShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.2)',
                      borderRadius: '8px',
                      backgroundColor: '#f8f8f8',
              }} elevation={0} style={{ width: '50%', maxHeight: '300px', overflowY: 'auto', padding: '20px' }}>

        <Typography variant="h6" gutterBottom>
          Your Sites
        </Typography>
        <List>
          {sites.length > 0 ? (
            sites.map((site) => (
              <ListItem key={site} button onClick={() => handleSiteClick(site)}>
                <ListItemText primary={site} />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2">No sites available</Typography>
          )}
        </List>
      </Paper>

      <Button
        variant="contained"
        onClick={() => navigate('/account-info')}
        style={{
          marginTop: '20px',  
          padding: '8px 16px', 
        }}
      >
        <Person /> My Account
      </Button>
      
      <Button
        variant="contained"
        onClick={handleSignOut}
        style={{
          backgroundColor: '#e53935', // Red background
          color: '#fff', // White text
          marginTop: '20px',
          padding: '8px 16px',
        }}
        startIcon={<Logout />}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#ef5350')} // Lighter red on hover
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#e53935')} // Reset to original color
      >
        Sign Out
      </Button>

    </Box>
  );
}

export default Admin;
