import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import './Login.css'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetMessage, setResetMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/'); // Redirect to the home page on successful login
    } catch (err) {
      setError('Failed to log in. Please check your email and password.');
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage('An email has been sent to your address with steps to reset your password.');
    } catch (err) {
      setError('Failed to send reset email. Please check the email address.');
    }
  };

  const toggleResetPassword = () => {
    setError('');
    setIsResetPassword(!isResetPassword);
  };

  return (
    <div className="login-container">
      {isResetPassword ? (
        <div className="login-box">
          <h2>Reset Password</h2>
          {resetMessage && <p className="success-message">{resetMessage}</p>}
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handlePasswordReset}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit">Send Password Reset Link</button>
          </form>
          <button className="forgot-password" onClick={toggleResetPassword}>
            Back To Login
          </button>
        </div>
      ) : (
        <div className="login-box">
          <img src="./HZVTLogo.png" alt="Logo" className="logo" />
          <h2>Data Window Login</h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
          <button className="forgot-password" onClick={toggleResetPassword}>
            Forgot Password?
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
