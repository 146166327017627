import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaEdit } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import { getData } from '../utils/dataLoader';
import './HeaderBar.css';

const HeaderBar = ({ siteName, selectedDate }) => {
    const [panelProd, setPanelProd] = useState(400); // Default in watts
    const [energyRate, setEnergyRate] = useState(0.42);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();

    const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}/${encodeURIComponent(selectedDate)}`;
    const siteDataUrl = `${baseUrl}/siteData.json`;

    useEffect(() => {
        const fetchSiteData = async () => {
            try {
                const response = await axios.get(siteDataUrl);
                const siteData = response.data;

                setPanelProd((siteData.panelProd || 0.5) * 1000); // Convert to watts for display
                setEnergyRate(siteData.kwValue || 0.12);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching site data:', err);
                setError('Could not load site data');
                setLoading(false);
            }
        };

        fetchSiteData();
    }, [siteDataUrl]);

    const saveSiteData = async () => {
        setSaveLoading(true);
        try {
            const updatedData = {
                siteName,
                selectedDate,
                data: {
                    Panel_prod: panelProd / 1000, // Convert back to kW for storage
                    KW_value: energyRate,
                },
            };

            const response = await axios.put('/api/saveSiteData', updatedData, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.status === 200) {
                setSuccessMessage('Settings saved successfully');
                setTimeout(() => setSuccessMessage(''), 3000); // Clears message after 3 seconds
            } else {
                alert('Failed to save settings');
            }
            setIsEditing(false);
        } catch (err) {
            console.error('Error saving site data:', err);
            alert('Failed to save settings');
        } finally {
            setSaveLoading(false);
        }
    };

    const handlePanelProdChange = (e) => {
        const value = parseFloat(e.target.value);
        if (value > 0) {
            setPanelProd(value);
        }
    };
    
    const handleEnergyRateChange = (e) => {
        const value = parseFloat(e.target.value);
        if (value > 0) {
            setEnergyRate(value);
        }
    };
    

    return (
        <div className="header-bar">
            <div className="logo-section">
                <img src="/HZVTLogo.png" alt="Site Logo" className="site-logo" />
            </div>


            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <div className="settings-section">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <CSSTransition
                        in={!isEditing}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className="display-mode">
                            <p>Per Panel Production: <strong>{panelProd} Watts</strong></p>
                            <p>Energy Rate: <strong>${energyRate} / kWh</strong></p>
                            <button className="edit-button" onClick={() => setIsEditing(true)}>
                                <FaEdit /> Edit
                            </button>
                        </div>
                    </CSSTransition>
                )}

                <CSSTransition in={isEditing} timeout={300} classNames="fade" unmountOnExit>
                    <div className="edit-mode">
                        <div className="edit-row">
                            <div className="edit-buttons">
                                <button onClick={saveSiteData} disabled={saveLoading}>
                                    {saveLoading ? <span className="spinner-icon" /> : 'Save'}
                                </button>
                                <button onClick={() => setIsEditing(false)}>Cancel</button>
                            </div>
                            <div className="input-group">
                                <div className="input-field">
                                    <label>Per Panel Production (Watts):</label>
                                    <input
                                        type="number"
                                        value={panelProd}
                                        onChange={handlePanelProdChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Energy Rate ($/kWh):</label>
                                    <input
                                        type="number"
                                        value={energyRate}
                                        onChange={handleEnergyRateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>

            <div className="account-icon-section">
                <button
                    className="account-info-button"
                    onClick={() => navigate('/account-info')}
                >
                    <FaUserCircle size={32} className="account-icon" />
                    <span>Account</span>
                </button>
            </div>


        </div>
    );
};

export default HeaderBar;
