import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from './loadingScreen';
import { FaExclamationTriangle, FaBolt } from 'react-icons/fa';
import { getData } from '../utils/dataLoader';
import axios from 'axios';
import './RevenueAnalysis.css';
import HeaderBar from './HeaderBar';
import TopNavBar from './TopNavBar';

import ReactSlider from 'react-slider';

const RevenueAnalysis = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { siteName, selectedDate } = location.state || {};

    const [kWhLost, setKWhLost] = useState(0);
    const [panelsAffected, setPanelsAffected] = useState(0);
    const [revenueLost, setRevenueLost] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [kWPerPanel] = useState(0.5); // Default kW per panel
    const [energyRate] = useState(0.12);
    const [faultRevenueData, setFaultRevenueData] = useState([]);

    const [revenueRange, setRevenueRange] = useState([0, 1000]);
    const [viewByInverter, setViewByInverter] = useState(true); 
    const [sliderMaxRevenueLoss, setSliderMaxRevenueLoss] = useState(0);
    

    const PVWATTS_API_URL = 'https://developer.nrel.gov/api/pvwatts/v6.json';
    const PVWATTS_API_KEY = process.env.REACT_APP_PVWATTS_API_KEY;

    useEffect(() => {
        if (!siteName || !selectedDate) {
            navigate('/'); // Redirect to home if no siteName or selectedDate is present
        }
        console.log('Selected date in FaultData:', selectedDate); // Debugging log
    }, [siteName, selectedDate, navigate]);

    const fetchPVWattsData = async (lat, lng, systemSize) => {
        try {
            const response = await axios.get(PVWATTS_API_URL, {
                params: {
                    api_key: PVWATTS_API_KEY,
                    lat: lat,
                    lon: lng,
                    system_capacity: systemSize,
                    module_type: 0,
                    losses: 10,
                    array_type: 2,
                    tilt: 0,
                    azimuth: 180,
                },
            });
            return response.data.outputs;
        } catch (error) {
            console.error('Error fetching PVWatts data:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchRevenueData = async () => {
            const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}`;
            const siteConfigUrl = `${baseUrl}/siteConfig.json`;

            try {
                const response = await fetch(siteConfigUrl);
                const configData = await response.json();
                const dateConfig = configData.dateFolders.find(folder => folder.date === selectedDate);

                if (!dateConfig) {
                    throw new Error(`No configuration found for the selected date: ${selectedDate}`);
                }

                const layersConfig = dateConfig.layers;
                const IBConfig = dateConfig.IB;
                const levelsConfig = dateConfig.levels;

                const selectedDateFolder = encodeURIComponent(selectedDate);
                const fetchedData = await getData(`${baseUrl}/${selectedDateFolder}`, layersConfig, IBConfig, levelsConfig);
                setPanelsAffected(fetchedData.statistics.totalPanelsEffected);

                const faultData = Object.entries(fetchedData.effectedPanelsByRectangleId).map(([faultId, affectedPanels]) => {
                    const faultKW = affectedPanels * kWPerPanel;
                    const faultRevenueLoss = faultKW * energyRate * 365; // Annual loss
                    return {
                        faultId,
                        affectedPanels,
                        faultKW,
                        faultRevenueLoss,
                    };
                });

                faultData.sort((a, b) => b.faultRevenueLoss - a.faultRevenueLoss);
                setFaultRevenueData(faultData);

                                
                const calculatedMaxLoss = Math.max(...faultData.map(fault => fault.faultRevenueLoss), 1);
                setSliderMaxRevenueLoss(calculatedMaxLoss); // Set only once
                setRevenueRange([0, calculatedMaxLoss]);

                const totalPanelsAffected = faultData.reduce((sum, fault) => sum + fault.affectedPanels, 0);
                const impactedKW = totalPanelsAffected * kWPerPanel;
                const pvData = await fetchPVWattsData(fetchedData.center.lat, fetchedData.center.lng, impactedKW);

                if (pvData) {
                    const annualKWhLost = pvData.ac_annual.toFixed(2);
                    setKWhLost(annualKWhLost);
                    setRevenueLost((annualKWhLost * energyRate).toFixed(2));
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching revenue data:', error);
                setError('Failed to fetch revenue data');
                setLoading(false);
            }
        };

        fetchRevenueData();
    }, [siteName, selectedDate, kWPerPanel, energyRate]);

    const filteredFaults = faultRevenueData.filter(
        fault => fault.faultRevenueLoss >= revenueRange[0] && fault.faultRevenueLoss <= revenueRange[1]
    );

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <HeaderBar siteName={siteName} selectedDate={selectedDate} />
            <TopNavBar siteName={siteName} selectedDate={selectedDate} />
    
            <div className="revenue-impact-bar">
                <h2>Total Estimated Revenue Statistics</h2>
                <div className="main-figure-container">
                    <div className="main-figure-label">Estimated annual revenue loss due to faults</div>
                    <div className="main-figure">
                        ${parseFloat(revenueLost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </div>
                </div>

                <div className="additional-metrics">
                    <div className="metric">
                        <FaExclamationTriangle className="metric-icon" />
                        <span>{panelsAffected.toLocaleString()} Panels Affected</span>
                        <span> ({parseFloat(panelsAffected * kWPerPanel).toFixed(2)} kW)</span>
                    </div>
                    <p>
                        <FaBolt style={{ color: '#ff5555', textDecoration: 'line-through', marginRight: '8px' }} /> 
                        {parseFloat(kWhLost).toLocaleString()} kWh lost annually
                    </p>
                </div>
            </div>
    
    
            <div className="toggle-and-filter-section">
                <div className="view-by-toggle">
                    <span>View by: </span>
                    <button onClick={() => setViewByInverter(!viewByInverter)}>
                        {viewByInverter ? 'Individual Fault' : 'Inverter Block'}
                    </button>
                </div>
                
                <div className="revenue-filter-inline">
                    <label>Filter Revenue Loss Range:</label>
                    <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="thumb"
                        trackClassName="track"
                        min={0}
                        max={sliderMaxRevenueLoss}
                        value={revenueRange}
                        onChange={setRevenueRange}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                    />
                    <span>
                        ${revenueRange[0].toLocaleString(undefined, { minimumFractionDigits: 2 })} - 
                        ${revenueRange[1].toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </span>
                </div>
            </div>
    
            <div className="fault-revenue-table">
                <h3>{viewByInverter ? 'Revenue Lost by Inverter Block' : 'Revenue Lost by Individual Fault'}</h3>
                <table>
                    <thead>
                        <tr>
                            <th>{viewByInverter ? 'Inverter Block' : 'Fault ID'}</th>
                            <th>Panels Affected</th>
                            <th>kW Lost</th>
                            <th>Annual Revenue Lost ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredFaults.map(fault => (
                            <tr key={fault.faultId}>
                                <td>{fault.faultId}</td>
                                <td>{fault.affectedPanels}</td>
                                <td>{fault.faultKW.toFixed(2)} kW</td>
                                <td>${fault.faultRevenueLoss.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RevenueAnalysis;