import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './FilterMenu.css';

const FilterMenu = ({ 
  categories, 
  selectedCategories, 
  handleCategoryChange, 
  handleOverlayToggle, 
  otherOverlays, 
  overlayVisibility, 
  selectedRectangle, 
  selectedFault,
  imagesByFaultId,
  imagesByRectangleId 
}) => {
  const isOverlayVisible = (index) => (overlayVisibility & (1 << index)) !== 0;

  const [annualRevenueLoss, setAnnualRevenueLoss] = useState(null);

  const images = selectedRectangle ? imagesByRectangleId[selectedRectangle] || [] : [];
  const faultImages = selectedFault ? imagesByFaultId[selectedFault.failure_location] || [] : [];

  const categoryData = images.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = { category: item.category, affectedPanels: 0 };
    }
    acc[item.category].affectedPanels += item.effected_panels || 0;
    return acc;
  }, {});

  const tableData = Object.values(categoryData);
  const totalAffectedPanels = tableData.reduce((total, row) => total + row.affectedPanels, 0);

  const affectedPanels = selectedFault?.effected_panels || 0;
  const kWPerPanel = 0.5; // Production per panel in kW
  const revenuePerKW = 0.12; // Revenue rate per kW
  const systemSizeKW = affectedPanels * kWPerPanel;

  const fetchPVWattsData = async (lat, lng, systemSize) => {
    try {
      const response = await axios.get('https://developer.nrel.gov/api/pvwatts/v6.json', {
        params: {
          api_key: process.env.REACT_APP_PVWATTS_API_KEY,
          lat: lat,
          lon: lng,
          system_capacity: systemSize,
          module_type: 0,
          losses: 10,
          array_type: 2,
          tilt: 0,
          azimuth: 180
        }
      });
      return response.data.outputs.ac_annual;
    } catch (error) {
      console.error('Error fetching PVWatts data:', error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedFault && systemSizeKW > 0) {
      fetchPVWattsData(selectedFault.lat, selectedFault.lng, systemSizeKW)
        .then((annualProduction) => {
          if (annualProduction) {
            const estimatedRevenueLoss = annualProduction * revenuePerKW;
            setAnnualRevenueLoss(estimatedRevenueLoss.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
          } else {
            setAnnualRevenueLoss('N/A');
          }
        });
    }
  }, [selectedFault, systemSizeKW]);

  return (
    <div className="filter-menu">

      {selectedRectangle && images.length > 0 && (
        <div className="carousel-container">
          <h2>Images for {selectedRectangle}</h2>
          <Carousel
            key={selectedRectangle} 
            showThumbs={false}
            showStatus={true}
            showIndicators={false}
          >
            {images.map((item, index) => (
              <div key={index}>
                <div className="carousel-item-container">
                  <img 
                    src={item.imagePath} 
                    alt={`Failure at ${item.failure_location}`} 
                  />
                </div>
                <div className="carousel-caption">
                  <p>Failure Type: {item.category}</p>
                  <p>Location: {item.failure_location}</p>
                  <p>Lat: {item.lat}</p>
                  <p>Lng: {item.lng}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}

      {selectedRectangle && tableData.length > 0 && (
        <div className="filter-table-container">
          <h2>{selectedRectangle} Failure Statistics</h2>
          <table className="filter-data-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Affected Panels</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.category}</td>
                  <td>{row.affectedPanels}</td>
                </tr>
              ))}

              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{totalAffectedPanels}</strong></td>
              </tr>
            </tbody>
          </table>
          <p>     </p>
          <p>     </p>
          <p>     </p>
          <p>     </p>
        </div>
      )}

      {selectedFault && faultImages.length > 0 && (
        <div className="fault-details">
          <h3>Fault Details</h3>
          <div className="carousel-caption">
            <p><strong>Failure Type:</strong> {selectedFault.category}</p>
            <p><strong>Location:</strong> {selectedFault.failure_location}</p>
            <p><strong>Lat:</strong> {selectedFault.lat}</p>
            <p><strong>Lng:</strong> {selectedFault.lng}</p>
            <p><strong>Repair Status:</strong> {selectedFault.repair_status || 'Pending'}</p>
            
            {/* New Data Points */}
            <p><strong>Affected Panels:</strong> {affectedPanels || 'N/A'}</p>
            <p><strong>Estimated KW Loss:</strong> {systemSizeKW ? `${systemSizeKW} kW` : 'N/A'}</p>
            <p><strong>Estimated Annual Revenue Loss:</strong> {annualRevenueLoss ? `$${annualRevenueLoss}` : 'N/A'}</p>
          </div>
          
          {/* Render fault image if available */}
          {faultImages.length > 0 && (
            <div className="carousel-container">
              <img 
                src={faultImages[0].imagePath} 
                alt={`Failure at ${selectedFault.failure_location}`} 
                style={{ width: '100%', maxHeight: '100px', objectFit: 'contain', marginTop: '10px' }}
              />
            </div>
          )}
        </div>
      )}

    </div>
  );
};

export default FilterMenu;
