import React, { useState, useEffect } from 'react';
import {motion} from 'framer-motion';
import TopNavBar from './TopNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from './loadingScreen';
import 'animate.css';
import './FaultData.css';
import { FaUserCircle, FaPlusCircle, FaMinusCircle, FaDownload, FaFilter } from 'react-icons/fa'; // Added FaFilter icon for filter menu
import { getData } from '../utils/dataLoader';
import HeaderBar from './HeaderBar';

const FaultData = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { siteName, selectedDate } = location.state || {};

    const [faultData, setFaultData] = useState([]);
    const [columns, setColumns] = useState(['Location']); // Start with Location as default column
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // Data to be shown after filtering
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false); // Toggle for showing the dropdown
    const [availableAttributes, setAvailableAttributes] = useState([
        'Level', 'Category', 'Affected Panels'
    ]); // Available options for the dropdown
    const [filterMenuVisible, setFilterMenuVisible] = useState(false); // State for showing/hiding the filter menu

    // State for filters
    const [selectedLevels, setSelectedLevels] = useState([]); // To store checked levels
    const [availableLevels, setAvailableLevels] = useState([]); // Dynamic levels from data
    const [selectedCategories, setSelectedCategories] = useState([]); // To store checked categories
    const [availableCategories, setAvailableCategories] = useState([]); // Dynamic categories from data

    // Ensure we have siteName and selectedDate or redirect
    useEffect(() => {
        if (!siteName || !selectedDate) {
            navigate('/'); // Redirect to home if no siteName or selectedDate is present
        }
        console.log('Selected date in FaultData:', selectedDate); // Debugging log
    }, [siteName, selectedDate, navigate]);

    // Fetch configuration and data
    useEffect(() => {
        const fetchConfigAndData = async () => {
            const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}`;
            const siteConfigUrl = `${baseUrl}/siteConfig.json`;

            try {
                // Fetch the site configuration
                const response = await fetch(siteConfigUrl);
                const configData = await response.json();

                // Find the configuration for the selected date
                const dateConfig = configData.dateFolders.find(
                    (folder) => folder.date === selectedDate
                );

                if (!dateConfig) {
                    throw new Error(`No configuration found for the selected date: ${selectedDate}`);
                }

                // Extract configurations for layers, IB, and levels
                const layersConfig = dateConfig.layers;
                const IBConfig = dateConfig.IB;
                const levelsConfig = dateConfig.levels;

                // Fetch the actual data using getData (from dataLoader.js)
                const selectedDateFolder = encodeURIComponent(selectedDate);
                const fetchedData = await getData(`${baseUrl}/${selectedDateFolder}`, layersConfig, IBConfig, levelsConfig);

                // Populate initial rows with just the Location data
                const rows = fetchedData.annotations.map((annotation) => ({
                    Location: annotation.failure_location,
                    Level: annotation.level,
                    Category: annotation.category,
                    'Affected Panels': annotation.effected_panels || 0,
                }));

                // Set the levels and categories dynamically from data
                const uniqueLevels = [...new Set(rows.map(row => row.Level))];
                const uniqueCategories = [...new Set(rows.map(row => row.Category))];
                
                setAvailableLevels(uniqueLevels);
                setAvailableCategories(uniqueCategories);
                
                setData(rows);
                setFilteredData(rows); // Initially, filtered data is the same as the complete data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching fault data:', error);
                setError('Failed to fetch fault data');
                setLoading(false);
            }
        };

        fetchConfigAndData();
    }, [siteName, selectedDate]);

    // Add a new column dynamically
    const addColumn = (attribute) => {
        if (!columns.includes(attribute)) {
            setColumns([...columns, attribute]);
            setAvailableAttributes(availableAttributes.filter(attr => attr !== attribute)); // Remove selected attribute from dropdown options
            setShowDropdown(false); // Close the dropdown after selecting a column
        }
    };

    // Remove a column dynamically
    const removeColumn = (attribute) => {
        if (attribute !== 'Location') { // Ensure we don't remove the Location column
            setColumns(columns.filter(col => col !== attribute));
            setAvailableAttributes([...availableAttributes, attribute]); // Add removed attribute back to the dropdown options
        }
    };

    // Toggle Dropdown visibility
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    // Export table to CSV
    const exportToCSV = () => {
        const csvRows = [];

        // Add headers (column names)
        csvRows.push(columns.join(','));

        // Add data rows
        filteredData.forEach(row => {
            const values = columns.map(col => `"${row[col] || ''}"`);
            csvRows.push(values.join(','));
        });

        // Create CSV string
        const csvString = csvRows.join('\n');

        // Create a downloadable CSV file
        const blob = new Blob([csvString], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'fault_data_table.csv';
        link.click();
    };

    // Handle filtering by levels and categories
    const handleLevelChange = (level) => {
        let updatedLevels = [...selectedLevels];
        if (selectedLevels.includes(level)) {
            updatedLevels = updatedLevels.filter(l => l !== level);
        } else {
            updatedLevels.push(level);
        }
        setSelectedLevels(updatedLevels);
        filterData(updatedLevels, selectedCategories);
    };

    const handleCategoryChange = (category) => {
        let updatedCategories = [...selectedCategories];
        if (selectedCategories.includes(category)) {
            updatedCategories = updatedCategories.filter(c => c !== category);
        } else {
            updatedCategories.push(category);
        }
        setSelectedCategories(updatedCategories);
        filterData(selectedLevels, updatedCategories);
    };

    // Filtering function
    const filterData = (levels, categories) => {
        const filtered = data.filter(row => {
            const levelMatch = levels.length === 0 || levels.includes(row.Level);
            const categoryMatch = categories.length === 0 || categories.includes(row.Category);
            return levelMatch && categoryMatch;
        });
        setFilteredData(filtered);
    };

    // Toggle the visibility of the filter menu
    const toggleFilterMenu = () => {
        setFilterMenuVisible((prev) => !prev);
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            
            <HeaderBar siteName={siteName} selectedDate={selectedDate} />
            <TopNavBar siteName={siteName} selectedDate={selectedDate}/>

            {/* Export to CSV Button at the Top */}
            <div className="export-button-section">
                <button onClick={exportToCSV} className="export-button">
                    <FaDownload size={16} style={{ marginRight: '8px' }} />
                    Export to CSV
                </button>
            </div>

            <h1>Fault Data View</h1>

            {/* Filter Results Button with Dropdown Icon */}
            <div className="filter-toggle">
                <button className="filter-button" onClick={toggleFilterMenu}>
                    <FaFilter size={16} style={{ marginRight: '8px' }} />
                    Filter Results
                </button>
            </div>

            {/* Filter Menu for Levels and Categories */}
            <motion.div
                className="fault-data-filter-menu"
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: filterMenuVisible ? "auto" : 0, opacity: filterMenuVisible ? 1 : 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            >
                <div className="filter-section">
                    <h3>Filter by Levels</h3>
                    {availableLevels.map((level, index) => (
                        <div key={index}>
                            <input
                                type="checkbox"
                                checked={selectedLevels.includes(level)}
                                onChange={() => handleLevelChange(level)}
                            />
                            <label>{level}</label>
                        </div>
                    ))}
                </div>

                <div className="filter-section">
                    <h3>Filter by Categories</h3>
                    {availableCategories.map((category, index) => (
                        <div key={index}>
                            <input
                                type="checkbox"
                                checked={selectedCategories.includes(category)}
                                onChange={() => handleCategoryChange(category)}
                            />
                            <label>{category}</label>
                        </div>
                    ))}
                </div>
            </motion.div>

            {/* Fault Data Table */}
            <div className="table-container">
                <table>
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index}>
                                <div className="header-content">
                                    <span>{col}</span>
                                    {col !== 'Location' && (
                                        <FaMinusCircle
                                            size={14}
                                            className="remove-column-icon"
                                            onClick={() => removeColumn(col)}
                                        />
                                    )}
                                </div>
                            </th>
                        ))}
                        {availableAttributes.length > 0 && (
                            <th>
                                <div className="add-column-container">
                                    <FaPlusCircle
                                        size={24}
                                        className="add-column-icon"
                                        onClick={toggleDropdown}
                                    />
                                    {showDropdown && (
                                        <ul className="dropdown-menu">
                                            {availableAttributes.map((attribute, index) => (
                                                <li key={index} onClick={() => addColumn(attribute)}>
                                                    {attribute}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </th>
                        )}
                    </tr>
                </thead>
                    <tbody>
                        {filteredData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {columns.map((col, colIndex) => (
                                    <td key={colIndex}>
                                        {/* Displaying text instead of input fields */}
                                        {row[col]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FaultData;
